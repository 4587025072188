// Angular
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// third party
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SkeletonModule } from '@app/skeleton/skeleton.module';
// internal version of fresco
import {
  DfButtonsModule,
  DfFormlyModule,
  DfIconModule,
  DfInputsModule,
  DfLocalNotificationModule,
  DfPopoverModule,
  DfSpinnerButtonModule,
  DfSpinnerModule,
} from '@lib/fresco';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbProgressbarModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NavigationModule } from '@app/navigation/navigation.module';
import { DgxItemRoleModule } from './directives/item-role/item-role.module';
import { OrgAnnouncementsModule } from '@app/shared/components/org-announcements/org-announcements.module';
import { IconsModule } from '@degreed/apollo-angular';

// directives
import { LoaderDirective } from '@app/shared/dynamic/loader.directive';
import { PopoverTriggerDirective } from './components/popover/popover-trigger.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DatepickerDataDgatDirective } from './directives/datepicker-data-dgat.directive';
import { FallbackDirective } from './directives/fallback.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { ImageRatioDirective } from './directives/image-ratio.directive';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { MaxTimeValidationDirective } from './directives/max-time-validation.directive';
import { MinTimeValidationDirective } from './directives/min-time-validation.directive';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { SecureBlankTargetDirective } from './directives/secure-blank-target.directive';
import { SortableDirective } from './directives/sortable.directive';
import { StickyDirective } from './directives/sticky.directive';
import { TrackingAreaDirective } from './directives/tracking-area.directive';
import { TrapFocusDirective } from './directives/trap-focus.directive';

// pipes
import { AnchorifyPipe } from './pipes/anchorify.pipe';
import { BlobifyUrlPipe } from './pipes/blobify-url.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { ChunkedPipe } from './pipes/chunked.pipe';
import { ClosestWordLimitPipe } from './pipes/closest-word-limit.pipe';
import { ContentActionPipe } from './pipes/content-action.pipe';
import { DgxCurrencyPipe } from './pipes/currency.pipe';
import { DecodeHtmlPipe } from './pipes/decode-html.pipe';
import { DisplayTypePipe } from './pipes/display-type.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { EncodeToEntitiesPipe } from './pipes/encode-to-entities.pipe';
import { EscapeCurlysPipe } from './pipes/escape-curlys.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { GetSkillTypePipe } from './pipes/get-skill-type.pipe';
import { HighlightSearchTextPipe } from './pipes/highlight-search-text.pipe';
import { HtmlTranslatedPipe } from './pipes/html-translated.pipe';
import { HtmlPipe } from './pipes/html.pipe';
import { HtmlToPlaintextPipe } from './pipes/htmlToPlaintext.pipe';
import { InputToLearningResourcePipe } from './pipes/input-to-learning-resource.pipe';
import { IsIframePipe } from './pipes/is-iframe.pipe';
import { NgbDatePipe } from './pipes/ngb-date.pipe';
import { PascalCasePipe } from './pipes/pascal-case.pipe';
import { PointsPipe } from './pipes/points.pipe';
import { PrettyDurationPipe } from './pipes/pretty-duration.pipe';
import { PrettyTimeElapsedPipe } from './pipes/pretty-time-elapsed.pipe';
import { UnicodePipe } from './pipes/unicode.pipe';
import { ResourceTypeIconPipe } from './pipes/resource-type-icon.pipe';
import { StripMarkupPipe } from './pipes/strip-markup.pipe';

// components
import { FinraTextareaLimitComponent } from '@app/shared/components/finra-textarea-limit/finra-textarea-limit.component';
import { SearchFieldWrapperComponent } from '@app/shared/components/search/search-field-wrapper/search-field-wrapper.component';
import { SkipNavComponent } from '@app/shared/components/skip-nav/skip-nav.component';
import { SocialCounts } from '@app/shared/components/social-counts/social-counts.component';
import { OrgManagedNotificationComponent } from './../search/components/org-managed-notification/org-manage-notification.component';
import { ActionButtonLinkComponent } from './buttons/action-button-link/action-button-link.component';
import { ActionButtonComponent } from './buttons/action-button/action-button.component';
import { InputCompletionComponent } from './buttons/input-completion/input-completion.component';
import { AssignmentBadgeComponent } from './components/assignment-badge/assignment-badge.component';
import { CharacterLimitInputComponent } from './components/character-limit-input/character-limit-input.component';
import { ColumnConfiguratorComponent } from './components/column-configurator/column-configurator.component';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { ContentCardComponent } from './components/content/content-card/content-card.component';
import { LearningResourceCardComponent } from './components/content/learning-resource-card/learning-resource-card.component';
import { LearningResourceMetadataComponent } from './components/content/learning-resource-metadata/learning-resource-metadata.component';
import { DataBulkActionButtonComponent } from './components/data-table/data-bulk-actions/data-bulk-action-button/data-bulk-action-button.component';
import { DataBulkActionsComponent } from './components/data-table/data-bulk-actions/data-bulk-actions.component';
import { DataColumnDateComponent } from './components/data-table/data-columns/data-column-date/data-column-date.component';
import { DataColumnHighlightComponent } from './components/data-table/data-columns/data-column-highlight/data-column-highlight.component';
import { DataColumnListComponent } from './components/data-table/data-columns/data-column-list/data-column-list.component';
import { DataColumnPersonComponent } from './components/data-table/data-columns/data-column-person/data-column-person.component';
import { DataColumnVisibilityComponent } from './components/data-table/data-columns/data-column-visibility/data-column-visibility.component';
import { DataFiltersComponent } from './components/data-table/data-filters/data-filters.component';
import { DataSearchComponent } from './components/data-table/data-search/data-search.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { ExpandingTitleSectionComponent } from './components/expanding-title-section/expanding-title-section.component';
import { FilterComponent } from './components/filter/filter.component';
import { FooterGlassesComponent } from './components/footer-glasses/footer-glasses.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { ImageLayoutComponent } from './components/image-layout/image-layout.component';
import { LazyChunkComponent } from './components/lazy-chunk/lazy-chunk.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { DgxMenuModule } from './components/menu/menu.module';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { NoSearchResultsComponent } from './components/no-search-results/no-search-results.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ToastComponent } from './components/toast/toast.component';
// TODO: OpportunityCardComponent is being used by the FlexFramework.
// We can't import OpportunitiesSharedModule there because FlexFrameworkSharedModule is being imported into OpportunitiesSharedModule,
// so we need to leave the card and match badge where it is.
import { OpportunityMatchBadgeComponent } from '@app/opportunities/components/opportunity-match-badge/opportunity-match-badge.component';
import { OpportunityCardComponent } from './components/opportunity-card/opportunity-card.component';
import { ThumbsComponent } from './buttons/thumbs/thumbs.component';
import { AddTileComponent } from './components/add-tile/add-tile.component';
import { AddressSuggestComponent } from './components/address-suggest/address-suggest.component';
import { BulkActionsComponent } from './components/bulk-actions/bulk-actions.component';
import { ConfettiDropperComponent } from './components/confetti-dropper/confetti-dropper.component';
import { ConnectWithMentorButtonComponent } from './components/connect-with-mentor-button/connect-with-mentor-button.component';
import { LiveEventMetadataComponent } from './components/content/live-events/live-event-metadata/live-event-metadata.component';
import { LiveEventSessionComponent } from './components/content/live-events/live-event-session/live-event-session.component';
import { CookieBannerModule } from './components/cookie-banner/cookie-banner.module';
import { InputStatisticsComponent } from './components/input-statistics/input-statistics.component';
import { InviteFlowModalComponent } from './components/invite-flow/components/invite-flow-modal/invite-flow-modal.component';
import { InviteFlowComponent } from './components/invite-flow/components/invite-flow/invite-flow/invite-flow.component';
import { LinkRowComponent } from './components/link-row/link-row.component';
import { ModalModule } from './components/modal/modal.module';
import { OrgSelectorComponent } from './components/org-selector/org-selector.component';
import { PageSaveFooterComponent } from './components/page-save-footer/page-save-footer.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PanelListComponent } from './components/panel-list/panel-list.component';
import { PillSelectorComponent } from './components/pill-selector/pill-selector.component';
import { PopoverComponent } from './components/popover/popover.component';
import { PopularProvidersComponent } from './components/popular-providers/popular-providers.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ProfilePicModule } from './components/profile-pic/profile-pic.module';
import { ProviderComponent } from './components/provider/provider.component';
import { RatingSliderComponent } from './components/rating-slider/rating-slider.component';
import { ResourceResultComponent } from './components/resource-result/resource-result.component';
import { SelectReactiveComponent } from './components/select-reactive/select-reactive.component';
import { SkillStandardsSegmentCardComponent } from './components/skill-standards-segment-card/skill-standards-segment-card.component';
import { SocialPicsComponent } from './components/social-pics/social-pics.component';
import { SplitButtonMenuItemComponent } from './components/split-button/split-button-menu-item/split-button-menu-item.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { TaskAlertComponent } from './components/task-alert/task-alert.component';
import { TextExpanderComponent } from './components/text-expander/text-expander.component';
import { ThSortComponent } from './components/th-sort/th-sort.component';
import { TimeZonePickerComponent } from './components/time-zone-picker/time-zone-picker.component';
import { ToggleElementComponent } from './components/toggle-element/toggle-element.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UserRecommendationHeaderModule } from './components/user-recommendation-header/user-reccommendation-header.module';
import { UsersPicturesComponent } from './components/users-pictures/users-pictures.component';
import { ValidateFieldComponent } from './components/validate-field/validate-field.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { WizardNavigationComponent } from './components/wizard/wizard-navigation/wizard-navigation.component';
import { WizardTitleComponent } from './components/wizard/wizard-title/wizard-title.component';
import { PasteAsPlainTextDirective } from './directives/paste-as-plain-text.directive';
import { SortableListDirective } from './directives/sortable-list.directive';
import { SkillStandardsPublishStatusBadgeComponent } from './components/skill-standards-publish-status-badge/skill-standards-publish-status-badge.component';
import { SkillStandardsCardComponent } from './components/skill-standards-card/skill-standards-card.component';
import { BusinessRulesSharedModule } from '@app/business-rules/business-rules-shared.module';
import { CardComponent } from './components/card/card.component';
import { OptionalNavigationComponent } from './components/optional-navigation/optional-navigation.component';
import { ViewAcademyComponent } from './buttons/view-academy/view-academy.component';
import { SignalPopoverComponent } from './components/signal-popover/signal-popover.component';
import { StickyFilterBarComponent } from './components/sticky-filter-bar/sticky-filter-bar.component';
import { DgxSimpleFieldWrapperModule } from './components/form/simple-field-wrapper/simple-field-wrapper.module';
import { DgxVisibilityModule } from './components/visibility/visibility.module';
import { DgxCopyLinkModule } from './components/copy-link/copy-link.module';
import { DgxLineClampModule } from './directives/line-clamp/line-clamp.module';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { ReactiveFormsLabelComponent } from './components/reactive-forms-label/reactive-forms-label.component';
import { EngagedLearnerModalComponent } from './components/engaged-learner-modal/engaged-learner-modal.component';
import { SelectComponent } from './components/select/select.component';
@NgModule({
  declarations: [
    /* pipes */
    AnchorifyPipe,
    BlobifyUrlPipe,
    CamelCasePipe,
    ChunkedPipe,
    ClosestWordLimitPipe,
    ContentActionPipe,
    DecodeHtmlPipe,
    DgxCurrencyPipe,
    DisplayTypePipe,
    EllipsisPipe,
    EncodeToEntitiesPipe,
    EscapeCurlysPipe,
    FileSizePipe,
    GetSkillTypePipe,
    HighlightSearchTextPipe,
    HtmlPipe,
    HtmlToPlaintextPipe,
    HtmlTranslatedPipe,
    NgbDatePipe,
    InputToLearningResourcePipe,
    IsIframePipe,
    PointsPipe,
    PascalCasePipe,
    PrettyDurationPipe,
    PrettyTimeElapsedPipe,
    UnicodePipe,
    ResourceTypeIconPipe,
    StripMarkupPipe,
    /* directive */
    ClickOutsideDirective,
    DatepickerDataDgatDirective,
    FallbackDirective,
    FeatureToggleDirective,
    ImageRatioDirective,
    IntersectionObserverDirective,
    MinTimeValidationDirective,
    MaxTimeValidationDirective,
    PasteAsPlainTextDirective,
    PopoverTriggerDirective,
    RecaptchaDirective,
    SortableDirective,
    SortableListDirective,
    StickyDirective,
    TrackingAreaDirective,
    LoaderDirective,
    SecureBlankTargetDirective,
    LiveEventMetadataComponent,
    TaskAlertComponent,
    LiveEventSessionComponent,
    ToastComponent,
    TrapFocusDirective,
    /* components */
    ActionButtonComponent,
    ActionButtonLinkComponent,
    AddressSuggestComponent,
    OrgManagedNotificationComponent,
    AssignmentBadgeComponent,
    ColumnConfiguratorComponent,
    ContentCardComponent,
    BulkActionsComponent,
    CardComponent,
    ComboboxComponent,
    ConnectWithMentorButtonComponent,
    DataBulkActionsComponent,
    DataBulkActionButtonComponent,
    DataColumnDateComponent,
    DataColumnHighlightComponent,
    DataColumnListComponent,
    DataColumnPersonComponent,
    DataColumnVisibilityComponent,
    DataFiltersComponent,
    DataSearchComponent,
    DataTableComponent,
    DatePickerComponent,
    DownloadButtonComponent,
    FilterComponent,
    FooterGlassesComponent,
    ForbiddenComponent,
    LearningResourceCardComponent,
    LearningResourceMetadataComponent,
    InputCompletionComponent,
    LazyChunkComponent,
    LearningResourceMetadataComponent,
    LinkRowComponent,
    ListItemComponent,
    MonthPickerComponent,
    NoSearchResultsComponent,
    InviteFlowModalComponent,
    InviteFlowComponent,
    EngagedLearnerModalComponent,
    // TODO: Move OpportunityCardComponent and OpportunityMatchBadgeComponent to OpportunitiesSharedComponent ASAP. See above.
    OpportunityCardComponent,
    OpportunityMatchBadgeComponent,
    PageSaveFooterComponent,
    PaginationComponent,
    PanelListComponent,
    PillSelectorComponent,
    PopoverComponent,
    PopularProvidersComponent,
    ProfileMenuComponent,
    ProviderComponent,
    ProviderComponent,
    RatingSliderComponent,
    ResourceResultComponent,
    SelectReactiveComponent,
    SocialCounts,
    SplitButtonMenuItemComponent,
    SocialPicsComponent,
    SplitButtonComponent,
    TaskAlertComponent,
    TextExpanderComponent,
    TimeZonePickerComponent,
    ThSortComponent,
    ThumbsComponent,
    InputStatisticsComponent,
    AddTileComponent,
    ToggleElementComponent,
    UserCardComponent,
    UsersPicturesComponent,
    VideoPlayerComponent,
    WizardNavigationComponent,
    WizardTitleComponent,
    SkipNavComponent,
    FinraTextareaLimitComponent,
    ConfettiDropperComponent,
    OrgSelectorComponent,
    SkillStandardsCardComponent,
    SkillStandardsSegmentCardComponent,
    SkillStandardsPublishStatusBadgeComponent,
    ViewAcademyComponent,
    SignalPopoverComponent,
    StickyFilterBarComponent,
    DateRangeComponent,
  ],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    DfIconModule,
    DfSpinnerModule,
    DfLocalNotificationModule,
    DfSpinnerButtonModule,
    DfButtonsModule,
    DfInputsModule,
    DfFormlyModule,
    DgxCopyLinkModule,
    DgxLineClampModule,
    DgxSimpleFieldWrapperModule,
    DgxVisibilityModule,
    FormsModule,
    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgbProgressbarModule,
    RouterModule,
    NgxSliderModule,
    ScrollingModule,
    SkeletonModule,
    DfPopoverModule,
    CookieBannerModule,
    ModalModule,
    DgxMenuModule,
    DgxItemRoleModule,
    UserRecommendationHeaderModule,
    ProfilePicModule,
    BusinessRulesSharedModule,
    OrgAnnouncementsModule,
    NavigationModule,
    // standalone components
    AutofocusDirective,
    OptionalNavigationComponent,
    ValidateFieldComponent,
    CharacterLimitInputComponent,
    ReactiveFormsLabelComponent,
    SearchFieldWrapperComponent,
    ToggleSwitchComponent,
    ExpandingTitleSectionComponent,
    ImageLayoutComponent,
    NotFoundComponent,
    IconsModule,
    SelectComponent,
  ],
  providers: [
    /* List providers in the `shared-providers.module.ts` file */
  ],

  exports: [
    /* modules */
    OrgAnnouncementsModule,
    AssignmentBadgeComponent,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgbProgressbarModule,
    SkeletonModule,
    DfIconModule,
    DfSpinnerModule,
    DfLocalNotificationModule,
    DfSpinnerButtonModule,
    DfButtonsModule,
    DfInputsModule,
    DfPopoverModule,
    DgxCopyLinkModule,
    DgxLineClampModule,
    DgxSimpleFieldWrapperModule,
    DgxVisibilityModule,
    CookieBannerModule,
    ModalModule,
    DgxMenuModule,
    DgxItemRoleModule,
    UserRecommendationHeaderModule,
    ProfilePicModule,
    BusinessRulesSharedModule,
    /* pipes */
    AnchorifyPipe,
    BlobifyUrlPipe,
    CamelCasePipe,
    ChunkedPipe,
    ClosestWordLimitPipe,
    ContentActionPipe,
    DecodeHtmlPipe,
    DgxCurrencyPipe,
    DisplayTypePipe,
    EllipsisPipe,
    EncodeToEntitiesPipe,
    EscapeCurlysPipe,
    FileSizePipe,
    GetSkillTypePipe,
    HighlightSearchTextPipe,
    HtmlPipe,
    HtmlToPlaintextPipe,
    HtmlTranslatedPipe,
    InputToLearningResourcePipe,
    IsIframePipe,
    NgbDatePipe,
    PascalCasePipe,
    PointsPipe,
    PrettyDurationPipe,
    PrettyTimeElapsedPipe,
    UnicodePipe,
    ResourceTypeIconPipe,
    StripMarkupPipe,
    /* directive */
    ClickOutsideDirective,
    DatepickerDataDgatDirective,
    FallbackDirective,
    FeatureToggleDirective,
    ImageRatioDirective,
    IntersectionObserverDirective,
    MinTimeValidationDirective,
    MaxTimeValidationDirective,
    PasteAsPlainTextDirective,
    PopoverTriggerDirective,
    RecaptchaDirective,
    SortableDirective,
    SortableListDirective,
    StickyDirective,
    TrackingAreaDirective,
    LoaderDirective,
    SecureBlankTargetDirective,
    /* components */
    AddTileComponent,
    AddressSuggestComponent,
    OrgManagedNotificationComponent,
    ActionButtonComponent,
    ActionButtonLinkComponent,
    BulkActionsComponent,
    CardComponent,
    CharacterLimitInputComponent,
    ColumnConfiguratorComponent,
    ComboboxComponent,
    ContentCardComponent,
    ConnectWithMentorButtonComponent,
    ConfettiDropperComponent,
    DataBulkActionsComponent,
    DataBulkActionButtonComponent,
    DataColumnDateComponent,
    DataColumnHighlightComponent,
    DataColumnListComponent,
    DataColumnPersonComponent,
    DataColumnVisibilityComponent,
    DataFiltersComponent,
    DataSearchComponent,
    DataTableComponent,
    DatePickerComponent,
    DownloadButtonComponent,
    FilterComponent,
    FooterGlassesComponent,
    ForbiddenComponent,
    ImageLayoutComponent,
    InputCompletionComponent,
    LearningResourceCardComponent,
    LearningResourceMetadataComponent,
    InputCompletionComponent,
    LazyChunkComponent,
    LinkRowComponent,
    ListItemComponent,
    LiveEventMetadataComponent,
    MonthPickerComponent,
    NoSearchResultsComponent,
    // TODO: Move OpportunityCardComponent and OpportunityMatchBadgeComponent to OpportunitiesSharedComponent ASAP. See above.
    OpportunityCardComponent,
    OpportunityMatchBadgeComponent,
    PageSaveFooterComponent,
    PaginationComponent,
    PanelListComponent,
    PillSelectorComponent,
    PopoverComponent,
    PopularProvidersComponent,
    ProviderComponent,
    ProfileMenuComponent,
    RatingSliderComponent,
    ResourceResultComponent,
    SelectReactiveComponent,
    SplitButtonMenuItemComponent,
    SearchFieldWrapperComponent,
    SocialCounts,
    SocialPicsComponent,
    SplitButtonComponent,
    TaskAlertComponent,
    TextExpanderComponent,
    InputStatisticsComponent,
    TimeZonePickerComponent,
    ThSortComponent,
    ThumbsComponent,
    ToggleElementComponent,
    ToggleSwitchComponent,
    UserCardComponent,
    UsersPicturesComponent,
    ValidateFieldComponent,
    VideoPlayerComponent,
    WizardNavigationComponent,
    WizardTitleComponent,
    SkipNavComponent,
    FinraTextareaLimitComponent,
    ConfettiDropperComponent,
    OrgSelectorComponent,
    InviteFlowComponent,
    InviteFlowModalComponent,
    SkillStandardsCardComponent,
    SkillStandardsSegmentCardComponent,
    SkillStandardsPublishStatusBadgeComponent,
    ToastComponent,
    LiveEventSessionComponent,
    TrapFocusDirective,
    ViewAcademyComponent,
    SignalPopoverComponent,
    StickyFilterBarComponent,
    DateRangeComponent,
    ReactiveFormsLabelComponent,
  ],
})
export class SharedModule {}
